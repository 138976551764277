<template>
  <b-card no-body class="p-2">
    <h3 class="mb-2">
      {{
        habilityType.habilidadTipoId == 0
          ? $t("Routes.HabilityTypeAdd")
          : $t("Routes.HabilityTypeEdit")
      }}
    </h3>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <!-- name work area field -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              :name="$t('humanResources.habilityType.columns.name')"
              rules="required"
            >
              <b-form-group
                :label="$t('humanResources.habilityType.columns.name')"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-input v-model="habilityType.nombre" />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Description Field -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="Description"
              rules="required"
            >
              <b-form-group
                :label="$t('humanResources.habilityType.columns.description')"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-textarea v-model="habilityType.descripcion" />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row class="float-right mt-2 mb-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            <b-spinner v-if="addingHabilityType" small class="mr-1" />
            {{ habilityType.habilidadTipoId == 0 ? $t("Lists.Add") : $t("Lists.Edit") }}
          </b-button>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BFormTextarea,

  BFormInvalidFeedback,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import humanResources from '@/services/humanresources.service'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import router from '@/router'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BFormInvalidFeedback,
    BFormGroup,
    BFormTextarea,
    BButton,

    ValidationProvider,
    ValidationObserver,
  },
  props: {
    modalCall: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    const addingHabilityType = ref(false)
    const habilityType = ref({
      habilidadTipoId: 0,
      nombre: '',
      descripcion: '',
      archivado: false,
    })

    const blankHabilityType = {
      habilidadTipoId: 0,
      nombre: '',
      descripcion: '',
      archivado: false,
    }

    const {
      fetchHabilityType,
      createHabilityType,
      updateHabilityType,
    } = humanResources()

    const resetHabilityType = () => {
      habilityType.value = JSON.parse(JSON.stringify(blankHabilityType))
    }

    if (router.currentRoute.params.habilidadTipoId) {
      fetchHabilityType(router.currentRoute.params.habilidadTipoId, data => {
        habilityType.value = data
      })
    } else {
      habilityType.value = JSON.parse(JSON.stringify(blankHabilityType))
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetHabilityType)

    const onSubmit = () => {
      addingHabilityType.value = true
      if (habilityType.value.habilidadTipoId === 0) {
        createHabilityType(habilityType.value, () => {
          addingHabilityType.value = false
          if (props.modalCall) {
            emit('find-records')
          } else {
            router.push({ name: 'apps-human-resources-hability-type-list' })
          }
        })
      } else {
        updateHabilityType(habilityType.value.habilidadTipoId, habilityType.value, () => {
          addingHabilityType.value = false
          router.push({ name: 'apps-human-resources-hability-type-list' })
        })
      }
    }

    return {
      habilityType,
      addingHabilityType,
      required,
      getValidationState,
      refFormObserver,
      resetForm,
      onSubmit,

    }
  },
}
</script>
